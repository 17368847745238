

window.onscroll = function() {scrollRotate()};

function scrollRotate() {
  var navimg = document.getElementById("site-gfx-animation");
  navimg.style.transform = "rotate("+ (window.pageYOffset/15) + "deg)";
}


